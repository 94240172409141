<template>
  <v-container class="pa-4 pa-md-10">
    <template v-if="isLoadingNotification">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="24"
            class="mx-2"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col>
          <span class="font-weight-medium"
            >{{ $trans("notification_type_title") }}:</span
          ><br />
          {{ $trans("notification_type_" + notificationType) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="font-weight-medium mb-2">
            {{ $trans("to") }} ({{
              $trans("send_customer_notification_number_info").toLowerCase()
            }}: {{ recipients.length }}):
          </div>
          <div
            v-for="recipient in recipientsToDisplay"
            :key="recipient.id"
            class="mb-2"
          >
            {{ recipient[recipientContactField] }}
            <span v-if="recipient.user_id || recipient.employee_id">
              ({{
                recipient.user_id
                  ? $trans("id") + ": " + recipient.user_id
                  : $trans("id") + ": " + recipient.employee_id
              }})
            </span>
            <span v-if="recipient.usage"
              >({{ $trans("usage") }}: {{ recipient.usage }})</span
            >
            <div
              v-for="status in recipient.statuses"
              :key="status.created_at"
              class="d-flex align-center"
            >
              <v-tooltip v-if="status.details" bottom>
                <template #activator="{ on, attr }">
                  <v-icon color="error" left v-bind="attr" v-on="on"
                    >$warning</v-icon
                  >
                </template>
                {{ status.details }}
              </v-tooltip>
              <span>
                {{ $trans("notification_status_" + status.status) }},
                {{
                  status.created_at
                    | dateTime($helpers.secondHourDayMonthAndYearDate)
                }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="recipients.length > 5">
        <v-col>
          <v-btn
            color="primary"
            @click="showAllRecipients = !showAllRecipients"
          >
            <span v-if="!showAllRecipients">
              {{ $trans("expand") }} (+{{ remainingRecipients }}
              {{ $trans("more") }})
            </span>
            <span v-else>{{ $trans("collapse") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="subject">
        <v-col cols="12">
          <span class="font-weight-medium"
            >{{ $trans("notification_subject") }}:</span
          ><br />
          {{ subject }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <strong>{{ $trans("notification_content") }}:</strong>
          <template v-if="notificationType === notificationTypes.email">
            <iframe
              ref="iframe"
              class="mt-4"
              :srcdoc="content"
              sandbox="allow-same-origin"
              style="width: 100%; border: none"
              @load="htmlIframeLoaded"
            />
          </template>
          <div v-else class="mt-4">{{ content }}</div>
        </v-col>
      </v-row>
      <v-row v-if="errorDetailsToDisplay && errorDetailsToDisplay.length > 0">
        <v-col>
          <span class="font-weight-medium">
            {{ $trans("errors") }} ({{ filteredErrorDetails.length }}):
          </span>
          <calendesk-warning-message>
            <div
              v-for="errorDetail in errorDetailsToDisplay"
              :key="errorDetail.id"
            >
              {{ errorDetail[recipientContactField] }} ({{
                errorDetail.user_id
                  ? $trans("id") + ": " + errorDetail.user_id
                  : errorDetail.employee_id
                    ? $trans("id") + ": " + errorDetail.employee_id
                    : ""
              }}):
              {{ errorDetail.error_details }}
            </div>
          </calendesk-warning-message>
        </v-col>
      </v-row>
      <v-row v-if="filteredErrorDetails && filteredErrorDetails.length > 5">
        <v-col>
          <v-btn
            color="primary"
            @click="showAllErrorDetails = !showAllErrorDetails"
          >
            <span v-if="!showAllErrorDetails">
              {{ $trans("expand") }} (+{{ remainingErrorDetails }}
              {{ $trans("more") }})
            </span>
            <span v-else>{{ $trans("collapse") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CalendeskWarningMessage from "@/lib/calendesk-js-library/components/CalendeskWarningMessage.vue";
import { notificationTypes } from "@/lib/calendesk-js-library/notificationTypes";
import { mapActions } from "vuex";

export default {
  name: "DisplayCustomerNotificationDialog",
  components: { CalendeskWarningMessage },
  mixins: [sharedActions],
  data() {
    return {
      subject: null,
      content: null,
      notificationType: null,
      customerType: null,
      recipients: [],
      showAllRecipients: false,
      showAllErrorDetails: false,
      isLoadingNotification: true,
    };
  },
  computed: {
    notificationTypes() {
      return notificationTypes;
    },
    // Identifies the correct contact field (email, phone, or user_id) based on notification type
    recipientContactField() {
      const contactFieldMap = {
        [this.$helpers.customerNotificationType.email]: "email",
        [this.$helpers.customerNotificationType.sms]: "phone",
        [this.$helpers.customerNotificationType.push]: "user_id",
      };
      return contactFieldMap[this.notificationType];
    },

    // Computes recipients to display (first 5 or all, depending on toggle)
    recipientsToDisplay() {
      if (!this.showAllRecipients && this.recipients.length > 5) {
        return this.recipients.slice(0, 5);
      }
      return this.recipients;
    },

    // Calculates the number of remaining recipients when the list is truncated
    remainingRecipients() {
      return this.recipients.length - 5;
    },

    // Filters and displays error details, with the same toggling logic
    filteredErrorDetails() {
      return this.recipients.filter((recipient) => recipient.error_details);
    },

    // Displays the error details based on the showAllErrorDetails flag
    errorDetailsToDisplay() {
      if (!this.showAllErrorDetails && this.filteredErrorDetails.length > 5) {
        return this.filteredErrorDetails.slice(0, 5);
      }
      return this.filteredErrorDetails;
    },

    // Calculates the number of remaining error details when the list is truncated
    remainingErrorDetails() {
      return this.filteredErrorDetails.length - 5;
    },
  },
  mounted() {
    this.isLoadingNotification = true;
    this.getCustomerNotificationData(
      this.getDialog.data.selectedNotification.id,
    )
      .then((customerNotification) => {
        this.customerType = customerNotification.customer_type;
        this.notificationType = customerNotification.notification_type;
        this.subject = customerNotification.subject;
        this.content = this.$options.filters.sanitizeHtml(
          customerNotification.content,
        );
        this.recipients = customerNotification.recipients;
      })
      .finally(() => {
        this.$nextTick(() => {
          this.isLoadingNotification = false;
        });
      });
  },
  methods: {
    ...mapActions({
      getCustomerNotificationData: "customerNotification/get",
    }),
    htmlIframeLoaded() {
      const iframe = this.$refs.iframe;

      if (iframe) {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // Adjust the height of the iframe
        iframe.style.height =
          iframeDocument.documentElement.scrollHeight + "px";

        // Prevent links from being clicked
        iframeDocument.querySelectorAll("a").forEach((link) => {
          link.addEventListener("click", (event) => {
            event.preventDefault(); // Block the link from being followed
          });
        });

        // Prevent buttons from being clicked
        iframeDocument.querySelectorAll("button").forEach((button) => {
          button.addEventListener("click", (event) => {
            event.preventDefault(); // Block the button from being clicked
          });
        });

        // Prevent input[type="submit"] and input[type="button"] from being clicked
        iframeDocument
          .querySelectorAll('input[type="submit"], input[type="button"]')
          .forEach((input) => {
            input.addEventListener("click", (event) => {
              event.preventDefault(); // Block the input button from being clicked
            });
          });

        // Prevent forms from being submitted
        iframeDocument.querySelectorAll("form").forEach((form) => {
          form.addEventListener("submit", (event) => {
            event.preventDefault(); // Block the form from being submitted
          });
        });

        // Prevent image map areas from being clicked
        iframeDocument.querySelectorAll("area").forEach((area) => {
          area.addEventListener("click", (event) => {
            event.preventDefault(); // Block the area from being clicked
          });
        });

        // Prevent elements with onclick attributes from being clicked
        iframeDocument.querySelectorAll("[onclick]").forEach((element) => {
          element.addEventListener("click", (event) => {
            event.preventDefault(); // Block elements with onclick from being clicked
          });
        });

        // Prevent elements with role="button" from being clicked
        iframeDocument
          .querySelectorAll('[role="button"]')
          .forEach((element) => {
            element.addEventListener("click", (event) => {
              event.preventDefault(); // Block elements with role="button" from being clicked
            });
          });
      }
    },
  },
};
</script>
